// 3rd party components
import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import MapSearch from '../components/common/MapboxMap/MapSearch/MapSearch'
import TypeaheadControl from '../components/policypage/TypeaheadControl/TypeaheadControl'
import TypeaheadResult from '../components/policypage/TypeaheadControl/TypeaheadResult'

// local components
import SEO from '../components/seo'
import Nav from '../components/Layout/Nav/Nav'
import Footer from '../components/Layout/Footer/Footer'

// assets and styles
import styles from '../assets/styles/homepage.module.scss'
import logo from '../assets/images/logo.svg'
import pin from '../assets/icons/pin.svg'
import flag from '../assets/icons/flag.svg'
import globe from '../assets/icons/globe.svg'
import policyIcon from '../assets/icons/policyIcon.svg'

import codebook from '../assets/codebook.json'
// get unique indicators (excluding subindicators)
const policyObj = {}
// prettier-ignore
codebook.data.forEach(
  policy => {
    const abbr = policy['Data element(s)'].split(':')[0].replace(/i/g, '')
    const name = policy['Data element(s)'].split(':')[1].trim()
    policyObj[abbr] = policyObj[abbr] || name
  }
)

const policies = Object.entries(policyObj).map(([key, label]) => ({
  key,
  label,
}))

const IndexPage = () => {
  // console.log(policies)
  // STATE  // --------------------------------------------------------------//
  const [loading, setLoading] = useState(false)

  // EFFECT HOOKS // -------—-------—-------—-------—-------—-------—-------—//
  useEffect(() => {
    // load resources from map page
    ___loader.loadPage('/map/')
  }, [])

  const [selectedPolicy, setSelectedPolicy] = useState()

  // JSX // -----------------------------------------------------------------//
  if (loading) return <div />
  else
    return (
      <>
        <SEO
          title="HIV Policy Lab"
          description="The HIV Policy Lab online platform systematically gathers and monitors laws and policies adopted by countries around the world—documenting where key HIV science has been translated into policy."
        />
        <Nav />
        <div className={styles.readReportBanner}>
          <div className={styles.textContainer}>
            <a href="/publications/global-hiv-policy-lab-report">Click here</a>{' '}
            to read the 2023 HIV Policy Lab Report
          </div>
        </div>
        <article className={styles.main}>
          {/* <div className={styles.cornerBox}>
            PRODUCED BY GEORGETOWN UNIVERSITY
          </div> */}
          <img
            className={styles.mainLogo}
            src={logo}
            alt={'HIV Policy Lab logo'}
          ></img>
          <p>
            The HIV Policy Lab online platform systematically gathers and
            monitors laws and policies adopted by countries around the
            world—documenting where key HIV science has been translated into
            policy. It is an open, growing global public resource that builds
            data from legal documents, government reporting, and independent
            research. In-depth country pages, an interactive global map and
            comparison across issues and countries is available. Please click
            below to enter.
          </p>
          <div className={styles.choicesRow}>
            <div className={styles.choice}>
              <div className={styles.iconContainer}>
                <img className={styles.flagIcon} src={flag}></img>
              </div>
              <h1>Find a country</h1>
              <MapSearch noToggle={true} className={'homepage'} links={true} />
            </div>
            <div className={styles.choice}>
              <div className={styles.iconContainer}>
                <img className={styles.flagIcon} src={policyIcon} />
              </div>
              <h1>Find a policy</h1>
              <TypeaheadControl
                className={styles.typeahead}
                placeholder={'Find a policy'}
                items={policies}
                value={selectedPolicy}
                onChange={policy => {
                  setSelectedPolicy(policy)
                  if (policy) navigate(`/policy/${policy.key}`)
                }}
                renderItem={({ key, label }) => (
                  <TypeaheadResult>
                    {key} - {label}
                  </TypeaheadResult>
                )}
              />
            </div>
            <div className={styles.choice}>
              <div className={styles.iconContainer}>
                <img className={styles.pinIcon} src={pin}></img>
              </div>
              <h1>Global map</h1>
              <div className={styles.buttonRow}>
                <Link to="/map/">
                  <button className={styles.homepageButton}>View map</button>
                </Link>
              </div>
            </div>
            <div className={styles.choice}>
              <div className={styles.iconContainer}>
                <img className={styles.globeIcon} src={globe}></img>
              </div>
              <h1>Compare countries</h1>
              <div className={styles.buttonRow}>
                <Link to="/compare/">
                  <button className={styles.homepageButton}>
                    View countries
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.explainer}>
            <i>
              This tool is designed to be used in Google Chrome or Mozilla
              Firefox.
            </i>
          </div>
        </article>
        {/* <Footer /> */}
      </>
    )
}

export default IndexPage
